<template>
  <div>

    <el-form label-position="right" label-width="90px">
      <el-form-item label="列数">
        <el-input-number size="small" v-model="currentPlugin.columnNum" :min="0" :max="30"></el-input-number> 列
      </el-form-item>

      <el-form-item label="格子间距">
        <el-input-number size="small" v-model="currentPlugin.gutter" :min="0" :max="30"></el-input-number> PX
      </el-form-item>

      <el-form-item label="图标大小">
        <el-input-number size="small" v-model="currentPlugin.iconSize" :min="28" :max="300"></el-input-number> PX
      </el-form-item>


      <el-divider>图片设置</el-divider>

      <el-collapse v-model="activeName" accordion>

        <draggable v-model="currentPlugin.grids" handle=".mover">
          <el-collapse-item v-for="(item, idx) in currentPlugin.grids" :key="idx" :name="idx">
            <template slot="title">
              <div :style="{ flexGrow: 1 }">
                <span class="mover" title="拖动改变顺序"><i class="header-icon el-icon-rank"></i></span> 图片{{ idx + 1 }}
                <span :style="{ float: 'right', marginRight: '10px' }">
                  <el-link type="danger" @click.stop="onPluginDelItem(idx)" :underline="false"><i
                      class="el-icon-circle-close" :style="{ fontSize: '18px' }"></i></el-link>
                </span>
              </div>
            </template>



            <el-form-item label="图片">
              <div class="cover-uploader">
                <div class="el-upload el-upload--text" @click="onUploadImage(idx)">
                  <el-image v-if="item.id" :src="item.url" class="cover" fit="contain"></el-image>
                  <i v-else class="el-icon-plus cover-uploader-icon"></i>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="名称">
              <el-input v-model="item.text" placeholder="导航名称" clearable />
            </el-form-item>
            <el-form-item label="点击图片">
              <el-button size="mini" style="margin-left:10px;" @click="onHandleSetting(idx)"
                icon="el-icon-s-tools">设置动作</el-button>
            </el-form-item>



          </el-collapse-item>
        </draggable>

      </el-collapse>

      <div class=" mt-10"><el-button @click="onPluginAddItem" type="success" size="small" icon="el-icon-plus"
          round>添加图片</el-button></div>


      <el-divider>提示</el-divider>
      <div class="help mt-10">建议使用统一尺寸的图片，使页面更整洁大气</div>

    </el-form>
    <!-- 图片选择 -->
    <image-upload-dialog :visible.sync="visible" source="navGrid" @confirm="onConfirm" />
    <!-- 链接选择 -->
    <!-- <action-link-dialog :visible.sync="visibleLink" source="diy" :showAll="true" @confirm="onConfirmLink"/> -->
    <!-- 动作设置 -->
    <handle-setting-dialog v-if="visibleHandle" :visible.sync="visibleHandle" :schema="currentSchema" source="diy"
      :showAll="true" @confirm="onConfirmHandle" />
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable';
import ImageUploadDialog from '../../ImageUploadDialog'
// import ActionLinkDialog from '../../ActionLinkDialog'
import HandleSettingDialog from '../../HandleSettingDialog'
import pluginSchema from '../../../pluginSchema/index'
import _ from 'lodash'


export default {
  name: 'navGrid',
  props: {},
  components: {
    draggable,
    ImageUploadDialog,
    HandleSettingDialog
  },
  computed: {
    ...mapGetters(['currentPlugin']),
    currentSchema: function () {
      return this.currentPlugin.grids[this.setLinkIndex];
    },
  },
  data: function () {
    return {
      visibleHandle: false,
      setLinkIndex: 0,
      activeName: 0,
      visible: false,
      setImagesIndex: 0
    }
  },
  methods: {
    onUploadImage(index) {
      // console.log('onUploadImage')
      this.visible = true;
      this.setImagesIndex = index;
    },
    onConfirm(imageData) {
      // console.log('imageData',imageData)
      this.currentPlugin.grids[this.setImagesIndex].id = imageData.id
      this.currentPlugin.grids[this.setImagesIndex].url = imageData.url
      this.currentPlugin.grids[this.setImagesIndex].alt = imageData.name
      this.currentPlugin.grids[this.setImagesIndex].filePath = imageData.path
    },
    // 添加项目
    onPluginAddItem() {
      const theSchema = pluginSchema.navGrid;
      this.currentPlugin.grids.push(_.cloneDeep(theSchema.grids[0]))
    },
    // 删除项目
    onPluginDelItem(index) {
      let that = this;
      this.currentPlugin.grids.splice(index, 1);
      if (this.currentPlugin.grids.length <= 0) {

        that.$message({
          message: '至少设置一项',
          type: 'warning'
        });

        //全部删除了就添加个默认项目
        setTimeout(function () {
          that.onPluginAddItem();

        }, 100);
      }

    },
    // onActionLink(index){
    //   // console.log('onUploadImage')
    //   this.setLinkIndex = index;
    //   this.visibleLink = true;
    // },
    // onConfirmLink(linkData){
    //   // console.log('linkData',linkData)
    //   this.currentPlugin.grids[this.setLinkIndex].link = linkData.qrview;
    //   this.currentPlugin.grids[this.setLinkIndex].path = linkData.weapp_path;
    //   this.currentPlugin.grids[this.setLinkIndex].appId = linkData.weapp_id;
    // },
    onHandleSetting(index) {
      // console.log('onUploadImage')
      this.visibleHandle = true;
      this.setLinkIndex = index;
    },
    onConfirmHandle(payload) {
      //  console.log('onConfirmHandle payload,', payload)
      // this.currentPlugin = { ...payload }

      // let itemHandle = this.currentPlugin.groups[this.editableTabsValue].grids[this.setLinkIndex]

      this.currentSchema.handle = payload.handle;
      this.currentSchema.link = payload.link;
      this.currentSchema.path = payload.path;
      this.currentSchema.appId = payload.appId;
      this.currentSchema.jumpType = payload.jumpType;
      this.currentSchema.shortLink = payload.shortLink;


      if (!_.isEmpty(payload.message)) {
        this.currentSchema.message = payload.message;
      }
      if (!_.isEmpty(payload.handle_call)) {
        this.currentSchema.handle_call = payload.handle_call;
      }
      if (!_.isEmpty(payload.handle_map)) {
        this.currentSchema.handle_map = payload.handle_map;
      }
      if (!_.isEmpty(payload.handle_copy)) {
        this.currentSchema.handle_copy = payload.handle_copy;
      }
      if (!_.isEmpty(payload.handle_wifi)) {
        this.currentSchema.handle_wifi = payload.handle_wifi;
      }
      if (!_.isEmpty(payload.handle_images)) {
        this.currentSchema.handle_images = payload.handle_images;
      }

    }

  }
}

</script>


<style></style>
