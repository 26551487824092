<template>
  <div>
    <div style="display:flex;" v-if="schema.type == 'sidebar'">
      <div style="border-right: 1px solid #fecaca;">
        <van-sidebar v-model="activeKey" style="width: 90px;">
          <van-sidebar-item v-for="(item, groupIdx) in schema.groups" :key="groupIdx" :title="item.text" />
        </van-sidebar>
      </div>
      <div style="width: 100%;">
        <van-cell-group>
          <van-cell v-for="(item, idx) in grids" :key="idx" :title="item.text" :is-link="item.handle != 'default'" >
            <div slot="label">
              <div> {{ item.label }}</div>
              <div v-if="item?.image?.id" style="margin: 5px 0;">
                <img :src="item?.image?.url" style="width: 100%;"  />
              </div>
            </div>
          </van-cell>
        </van-cell-group>

      </div>
    </div>
    <div v-if="schema.type == 'accordion'">

      <van-collapse v-model="schema.accordionActive" name="0" accordion>
        <van-collapse-item v-for="(item, groupIdx) in schema.groups" :key="groupIdx" :title="item.text"
          :name="groupIdx + ''">
          <van-cell-group>
            <!-- <van-cell v-for="(item, idx) in item?.grids" :key="idx" :title="item.text" :label="item.label"
              :is-link="item.handle != 'default'">
            </van-cell> -->

            <van-cell v-for="(item, idx) in grids" :key="idx" :title="item.text" :is-link="item.handle != 'default'">
              <div slot="label">
                <div> {{ item.label }}</div>
                <div v-if="item?.image?.id" style="margin: 5px 0;">
                  <img :src="item?.image?.url" style="width: 100%;" />
                </div>
              </div>
            </van-cell>
          </van-cell-group>

        </van-collapse-item>
      </van-collapse>
    </div>

  </div>
</template>

<script>
export default {
  name: 'collapse',
  props: {
    schema: {
      'type': Object,
      default: {},
      required: true
    },
    pluginIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeKey: 0,
      // accordionActive: '0'
    };
  },
  computed: {
    grids: function () {
      return this.schema.groups[this.activeKey].grids;
    }
  },
  methods: {
    // clickImage(e) {
    //   // console.log('clickImage e', e)
    //   e.stopPropagation();
    // },
    // actionPlugin(e){
    //   // console.log('actionPlugin e', e)
    // }
  }
}
</script>

<style></style>