export default {
    name: 'collapse',
    title: '折叠面板',
    type: 'sidebar',
    accordionActive:'',// 小程序默认打开第一个
    groups: [
        {
            text: '分组',
            grids: [{
                text: '1企业简介',
                label: '快速了解我们公司',
                handle: 'default',
                message: '',
                link: '',
                path: '',//小程序页面
                appId: '' //小程序appId
            }, {
                text: '1企业简介',
                label: '快速了解我们公司',
                handle: 'default',
                message: '',
                link: '',
                path: '',//小程序页面
                appId: '' //小程序appId
            }],
        }

    ],
    // grids: [{
    //     text: '企业简介',
    //     label: '快速了解我们公司',
    //     handle: 'none',
    //     message: '',
    //     link: '',
    //     path: '',//小程序页面
    //     appId: '' //小程序appId
    // }, {
    //     text: '企业简介',
    //     label: '快速了解我们公司',
    //     handle: 'none',
    //     message: '',
    //     link: '',
    //     path: '',//小程序页面
    //     appId: '' //小程序appId
    // }]
}