<template>
  <div>
    <el-dialog :visible="visible" width="95%" class="image-dialog" @close="onCancel"
      style="max-width: 900px; margin:0 auto;">

      <el-dialog width="95%" style="max-width: 500px; margin:0 auto;" title="H5跳转小程序" :visible.sync="innerVisible"
        :modal="false" append-to-body>
        <el-form label-position="right" label-width="120px" style="margin-top:10px;">
          <el-form-item label="小程序页面">
            <el-input v-model="formData.path" placeholder="如：pages/index/index?foo=bar"></el-input>
            <div>页面要先声明：<a class="help" href="https://www.2weima.com/article/read.html?scene=R6666L-hL666L-7CCEA1"
                target='_blank' style="color:#2563eb;cursor: pointer;">查看声明设置教程</a></div>
          </el-form-item>
          <el-form-item label="小程序appId">
            <el-input v-model="formData.appId" placeholder="如：wx81bdf0b17a44370d" />
          </el-form-item>
          <el-form-item label="小程序版本">
            <el-radio-group v-model="env_version">
              <el-radio label="release">正式版</el-radio>
              <el-radio label="trial">体验版</el-radio>
              <el-radio label="develop">开发版</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="小程序主体">
            <el-radio-group value="com">
              <el-radio label="com">企业/个体户/政务</el-radio>
              <el-radio label="r" disabled>个人（暂不支持）</el-radio>
            </el-radio-group>
          </el-form-item>


        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="onConfirmInner">确 定</el-button>
        </span>
      </el-dialog>

      <el-form label-position="right" label-width="120px" style="margin-top:10px; display: flex;">
        <!-- <el-form-item label="点击图标">
          <el-radio-group v-model="schema.handle" size="small">
            <el-radio-button label="default">无动作</el-radio-button>
            <el-radio-button label="none">返回上一页</el-radio-button>
            <el-radio-button label="link">跳转</el-radio-button>
            <el-radio-button label="message">消息</el-radio-button>
          </el-radio-group>
        </el-form-item> -->

        <el-tabs v-model="formData.handle" type="card" tab-position="left">
          <el-tab-pane label="无动作" name="default"></el-tab-pane>
          <el-tab-pane label="返回上一页" name="none"></el-tab-pane>
          <el-tab-pane label="跳转页面" name="link"></el-tab-pane>
          <el-tab-pane label="弹出消息" name="message"></el-tab-pane>
          <el-tab-pane label="打电话" name="call"></el-tab-pane>
          <el-tab-pane label="地图导航" name="map"></el-tab-pane>
          <el-tab-pane label="连接WIFI" name="wifi"></el-tab-pane>
          <el-tab-pane label="复制内容" name="copy"></el-tab-pane>
          <el-tab-pane label="看图" name="images"></el-tab-pane>
        </el-tabs>
        <div class="set-options" style="width:100%">

          <div v-if="formData.handle == 'default'">
            <el-alert title="不做任何处理" type="info"></el-alert>
          </div>
          <div v-else-if="formData.handle == 'none'">
            <el-alert title="点击时，返回上一页" type="info"></el-alert>
          </div>
          <div v-else-if="formData.handle == 'images'">


            <el-form-item label="上传图片">
              <el-button @click="onUploadImage">选择图片</el-button>
            </el-form-item>

            <div style="display:flex; flex-wrap: wrap;">


              <div v-for="(item, idx) in formData?.handle_images" :key="idx" :name="idx"
                style="width:128px;  margin:8px; position: relative;">

                <span :style="{ position: 'absolute', right: '-8px', top: '-8px' }">
                  <el-link type="danger" @click.stop="onPluginDelItem(idx)" :underline="false"><i
                      class="el-icon-circle-close" :style="{ fontSize: '18px' }"></i></el-link>
                </span>

                <div class="cover-uploader">
                  <div class="el-upload el-upload--text">
                    <el-image :src="item.url" class="cover" fit="contain" style="width:100%"></el-image>
                  </div>
                </div>
                <el-form-item label="排序" label-width="40px">
                  <el-input v-model="item.sort" placeholder="排序" @blur="onSortBlur"></el-input>
                </el-form-item>

              </div>

            </div>

          </div>

          <!-- {formData?.action == 'map' && <div className='mb-2'>
                    <Form.Input
                        field="handle_map.location.poiname"
                        initValue={formData?.handle_map?.location?.poiname}
                        helpText={<div className='text-gray-400'>{formData?.handle_map?.location?.poiaddress} {formData?.handle_map?.location?.poiname}</div>}
                        label="位置"
                        placeholder="请在地图中选择"
                        addonAfter={<Button type='primary' icon={<IconMapPin />} onClick={() => { setMapVisible({ ...mapVisible, visible: true }) }}>打开地图</Button>}
                        trigger='blur'
                        rules={[
                            { type: 'string' },
                            { required: true, message: '请在地图中选择位置' }
                        ]}
                    />
                </div>}
                 -->

          <!-- map -->
          <div v-if="formData.handle == 'map'">
            <el-form-item label="位置">
              <el-input v-model="formData.handle_map.location.poiname" placeholder="请在地图中选择">
                <el-button slot="append" @click="onActionMap">打开地图</el-button>
              </el-input>
            </el-form-item>
          </div>

          <!-- wifi -->
          <div v-if="formData.handle == 'wifi'">
            <el-form-item label="WIFI名称">
              <el-input v-model="formData.handle_wifi.ssid" placeholder="如：ChinaNet-2weima" />
            </el-form-item>
            <el-form-item label="WIFI密码">
              <el-input v-model="formData.handle_wifi.password" placeholder="如：ChinaNet-2weima" />
            </el-form-item>
            <el-form-item label="设备MAC地址">
              <el-input v-model="formData.handle_wifi.bssid" placeholder="不设置请留空，如：e8:65:d4:24:dc:a1" />
              <div><a class="help" href="https://docs.diycms.com/help/wifiinfo/" target='_blank'>如何查看设备地址？</a></div>
            </el-form-item>
          </div>


          <!-- call -->
          <el-form-item label="电话号码" v-if="formData.handle == 'call'">
            <el-input v-model="formData.handle_call.phone_number" placeholder="如：15807630051" />
          </el-form-item>

          <!-- copy -->
          <el-form-item label="复制内容" v-if="formData.handle == 'copy'">
            <el-input v-model="formData.handle_copy.message" type="textarea" :rows="4" placeholder="如：网址、电话、激活码.." />
          </el-form-item>

          <!-- message -->
          <el-form-item label="弹出消息" v-if="formData.handle == 'message'">
            <el-input v-model="formData.message" type="textarea" :rows="4" placeholder="如：请联系客服制作同款页面" />
          </el-form-item>

          <!-- link -->
          <template v-if="formData.handle == 'link'">
            <p class="help">H5打开：跳转网址</p>
            <el-form-item label="网址">
              <el-input v-model="formData.link" placeholder="如：https://www.2weima.com">
                <el-button slot="append" @click="onActionLink">选择</el-button>
              </el-input>
              <div class="help">H5跳转小程序，点击：<span style="color:#2563eb;cursor: pointer;"
                  @click="onInnerVisible">设置小程序网址</span></div>
            </el-form-item>
            <p class="help">小程序打开：跳转页面，支持第三方小程序</p>

            <el-form-item label="跳转方式">
              <el-radio-group v-model="formData.jumpType">
                <el-radio label="path">页面Path</el-radio>
                <el-radio label="shortLink">小程序链接</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="formData.jumpType == 'shortLink'">
              <el-form-item label="任意短链接">
                <el-input v-model="formData.shortLink" placeholder="如：#小程序://二维工坊/MwZhlexpQydEFEz" />
                <div><a class="help" href="https://www.2weima.com/article/read.html?scene=R6666L-YL666L-6B916B"
                    target='_blank' style="color:#2563eb;cursor: pointer;">短链接在那里获取的？</a></div>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="小程序页面">
                <el-input v-model="formData.path" placeholder="如：pages/index/index?foo=bar">
                  <el-button slot="append" @click="onActionLink">选择</el-button>
                </el-input>
              </el-form-item>
              <el-form-item label="小程序appId">
                <el-input v-model="formData.appId" placeholder="如：wx81bdf0b17a44370d" />
              </el-form-item>
            </template>

          </template>

        </div>
      </el-form>


      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>



    </el-dialog>

    <!-- 链接选择 -->
    <action-link-dialog :visible.sync="visibleLink" :source="source" :showAll="showAll" @confirm="onConfirmLink" />
    <!-- 链接选择 -->
    <handle-map-dialog v-if="visibleMap" :visible.sync="visibleMap" @confirm="onConfirmMap" />
    <!-- 图片选择 -->
    <image-upload-dialog :visible.sync="visibleImage" source="images" @confirm="onConfirmImage" />

  </div>
</template>
<script>
import formData from "../pluginSchema/modules/formData"
import ActionLinkDialog from './ActionLinkDialog'
import HandleMapDialog from './HandleMapDialog'
import ImageUploadDialog from './ImageUploadDialog'
// 默认设置
const defaultHandle = {
  handle: 'none',
  // handle_message
  message: '',
  // handle_link
  link: '',
  path: '',
  //小程序页面
  appId: '', //小程序appId
  shortLink: '',
  jumpType: 'path', // or shortLink
  handle_call: {
    phone_number: ''
  },
  handle_map: {
    location: {}
  },
  handle_copy: {
    message: ''
  },
  handle_wifi: {
    ssid: '',
    password: '',
    bssid: ''
  },
  handle_images: []
}

export default {
  name: 'HandleSettingDialog',
  components: {
    ActionLinkDialog,
    ImageUploadDialog,
    HandleMapDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    schema: {
      type: Object,
      default: {}
    },
    source: {
      type: String,
      default: ""
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    // console.log('this.schema', {
    //     ...defaultHandle,
    //     ...this.schema,
    //     handle: this.schema?.handle || "default"
    //   })
    return {
      env_version: 'release',
      innerVisible: false,
      visibleLink: false,
      visibleMap: false,
      visibleImage: false,
      formData: {
        ...defaultHandle,
        ...this.schema,
        handle: this.schema?.handle || "default"
      }
    }
  },
  // watch: {
  //   visible: function (val) {
  //     console.log('formData', this.formData)
  //   }
  // },
  methods: {
    onCancel() {
      this.$emit('update:visible', false)
    },
    onConfirm() {

      // console.log('formData', this.formData)
      //  return;

      this.$emit('update:visible', false)
      this.$emit('confirm', _.clone(this.formData))
    },
    onActionLink() {
      // console.log('onUploadImage')
      this.visibleLink = true;
    },
    onConfirmLink(linkData) {
      //  console.log('linkData', linkData)
      this.formData.link = linkData.qrview;
      this.formData.path = linkData.weapp_path;
      this.formData.appId = linkData.weapp_id;

    },
    onSortBlur() {
      // console.log('onSortBlur')
      this.formData.handle_images.sort((a, b) => a.sort - b.sort);
    },
    // 删除项目
    onPluginDelItem(index) {
      this.formData.handle_images.splice(index, 1);
    },
    onUploadImage() {
      // console.log('onUploadImage')
      this.visibleImage = true;
      // this.setImagesIndex = index;
    },
    onConfirmImage(imageData) {
      // console.log('imageData',imageData)
      if (!this.formData.handle_images) {
        this.formData.handle_images = [];
      }
      this.formData.handle_images.push({
        id: imageData.id,
        url: imageData.url,
        alt: imageData.name,
        filePath: imageData.path,
        sort: 10
      });
      // this.currentPlugin.grids[this.setImagesIndex].id = imageData.id
      // this.currentPlugin.grids[this.setImagesIndex].url = imageData.url
      // this.currentPlugin.grids[this.setImagesIndex].alt = imageData.name
      // this.currentPlugin.grids[this.setImagesIndex].filePath = imageData.path
    },
    onActionMap() {
      this.visibleMap = true;
    },
    onConfirmMap(location) {
      // console.log('location', location)
      this.formData.handle_map.location = location;

    },
    onInnerVisible() {
      this.innerVisible = true;
      return false;
    },
    onConfirmInner() {

      if (this.formData.path == '' || this.formData.appId == '') {
        this.$alert('小程序页面 和 小程序appId 都是必须填写的', '提示', {
          confirmButtonText: '确定',
          callback: (action) => {

          }
        });
        return false;
      }

      if (this.formData.path.startsWith('/')) {
        this.formData.path = this.formData.path.slice(1);
      }
      // console.log('this.formData.path',this.formData.path)

      let path = this.formData.path.split('?')[0];
      let query = this.formData.path.split('?')[1];

      let url = 'weixin://dl/business/?appid=' + this.formData.appId + '&path=' + path;
      if (query) {
        url += '&query=' + encodeURIComponent(query);
      }

      if (this.env_version != 'release') {
        url += '&env_version=' + this.env_version;
      }
      this.formData.link = url
      // console.log( url)
      this.innerVisible = false;
    }
  }
}
</script>
